import React, { Component } from "react";
import { Modal } from "@material-ui/core";
import CustomButton from "../Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";

class SimpleModal extends Component {
  render() {
    const { open, success, title, subText, onClose, content } = this.props;
    return (
      <Modal className="modal" open={open} onClose={onClose}>
        <div className="modal-paper">
          <div style={{ marginBottom: "15px" }} className="text-center">
            {success ? (
              <CheckCircleIcon className="modal-icon" />
            ) : (
              <ErrorIcon className="modal-icon-error" />
            )}
          </div>
          <div className="modal-title">{title}</div>
          <div style={{ marginTop: "10px", textAlign: "center" }}>
            <span>{content}</span>
          </div>
          <p>{subText}</p>
          <div style={{ marginTop: "40px" }} className="text-center">
            <CustomButton text="OK" buttonTheme="primary" onClick={onClose} />
          </div>
        </div>
      </Modal>
    );
  }
}

export default SimpleModal;
