import React, { Component } from "react";
import SignInWidget from "./signinWidget";
import * as ROUTES from "../../constants/route";
import news1 from "../../contents/img/news1.png";
import news2 from "../../contents/img/news2.png";
import news3 from "../../contents/img/news3.png";
import { withCookies } from "react-cookie";
import axios from "axios";

const INITIAL_STATE = {
  latestNews: [
    {
      img: news1,
      title: "News Letter Title here",
      text: "ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam...",
    },
    {
      img: news2,
      title: "News Letter Title here",
      text: "ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam...",
    },
    {
      img: news3,
      title: "News Letter Title here",
      text: "ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam...",
    },
  ],
  email: "",
  password: "",
  showPass: false,
  toggleAlert: false,
  severity: "warning",
  messageAlert: "",
  error: null,
  loading: false,
  submitDisabled: false,
  agreePolicy: "",
  resendPage: false,
};

class SigninContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.fb = props.props.firebase;
    this.history = props.props.history;
  }
componentDidMount() {
  const emailQuery = this.props.props.location.state?.email ?? false;
  
    if (emailQuery) {
      this.setState((prevState) => ({
        ...prevState,
        email: emailQuery,
      }));
    }else{
      console.log("error data query .")
    }
}
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      // this.validate(event);
    });
  };

  onClickShowPassword = () => {
    this.setState({ showPass: true });
  };

  onMouseDownPassword = () => {
    this.setState({ showPass: false });
  };

  onClickAgreePolicy = (event) => {
    event.target.checked
      ? this.setState({ agreePolicy: true })
      : this.setState({ agreePolicy: "" });
  };

  showErrorMessage = (message) => {
    this.setState(
      {
        toggleAlert: true,
        messageAlert: message,
        severity: "error",
      },
      () => {
        this.setState({ loading: false, submitDisabled: false });
      }
    );
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true, submitDisabled: true });
    const { email, password } = this.state;
    const isValid = email !== "" || password !== "";
    const { cookies } = this.props;
    if (!this.state.resendPage) {
      if (isValid) {
        this.fb
          .doSigninWithEmailAndPassword(email, password)
          .then((res) => {
            if (res) {
              return this.fb.getCustomClaims();
            }
          })
          .then((res) => {
            if (res) {
              if (res.claims.role === "client") {
                if (res.claims.email_verified) {
                  cookies.set("token", res.token, { path: "/" });
                  this.history.push(ROUTES.DASHBOARD);
                  this.setState({ loading: false, submitDisabled: false });
                } else {
                  this.fb.doSignOut();
                  axios
                    .post(
                      `${ROUTES.FUNCTION_BASE_URL}clients-client/checkNewUser`,
                      JSON.stringify({ email: res.claims.email }),
                      {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    )
                    .then((result) => {
                      if (result.data.ok) {
                        if (
                          result.data.data.verified === false &&
                          result.data.data.hasEntry === false
                        )
                          this.showErrorMessage("This email does not exist.");
                        else if (result.data.data.verified === false)
                          this.showErrorMessage(
                            "Your email is not yet verified. Please check your email inbox to verify your email with Rampver Online"
                          );
                      } else this.showErrorMessage(result.data.message);
                    })
                    .catch((error) => {
                      this.showErrorMessage(error.message);
                    });
                }
              } else {
                this.fb.doSignOut();
                this.showErrorMessage("Invalid Account");
              }
            }
          })
          .catch((error) => {
            let message = error.message;
            if (error.code === "auth/wrong-password")
              message = "Password doesn't match! Please try again.";

            if (error.code === "auth/user-not-found")
              message = "This email does not exist";

            this.showErrorMessage(message);
          });
      }
    } else {
      axios
        .post(
          `${ROUTES.FUNCTION_BASE_URL}clients-client/sendVerification`,
          JSON.stringify({ email: email }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.ok) {
            this.setState(
              {
                toggleAlert: true,
                messageAlert:
                  "Email Verification Sent! Please check your email",
                severity: "success",
              },
              () => {
                this.setState({
                  loading: false,
                  submitDisabled: false,
                  resendPage: false,
                });
              }
            );
          } else {
            this.showErrorMessage(res.data.message);
          }
        })
        .catch((error) => {
          let message = error.message;
          this.showErrorMessage(message);
        });
    }
  };

  onRenderLatestNews = () => {
    const news = this.state.latestNews.map((data) => {
      return (
        <div className="latest-news" key={data.img}>
          <img src={data.img} alt="test-img" />
          <div className="text">
            <h3 className="_title">{data.title}</h3>
            <p>{data.text}</p>
          </div>
        </div>
      );
    });

    return news;
  };

  validate = (event) => {
    const { email, password } = this.state;

    switch (event.target.name) {
      case "email":
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
          this.setState({ emailError: "Please input a valid email address." });
        } else {
          this.setState({ emailError: "" });
        }
        break;
      case "password":
        if (password.length !== 0) {
          this.setState({ passwordError: "" });
        } else {
          this.setState({ passwordError: "Please input password." });
        }
        break;
      default:
        break;
    }
  };

  changePage = () => {
    this.setState({ resendPage: !this.state.resendPage });
  };

  render() {
    return (
      <SignInWidget
        state={this.state}
        onChange={() => this.onChange.bind(this)}
        onSubmit={() => this.onSubmit.bind(this)}
        onClickShowPassword={() => this.onClickShowPassword.bind(this)}
        onMouseDownPassword={() => this.onMouseDownPassword.bind(this)}
        onClickSigninWithGoogle={() => this.onClickSigninWithGoogle.bind(this)}
        onRenderLatestNews={() => this.onRenderLatestNews.bind(this)}
        changePage={() => this.changePage.bind(this)}
        onClickAgreePolicy={this.onClickAgreePolicy}
        firebase={this.props.props.firebase}
      />
    );
  }
}

export default withCookies(SigninContainer);
