import React, { Component } from "react";
import ForgotPasswordWidget from "./ForgotPasswordWidget";
import * as ROUTES from "../../constants/route";
import Validators from "../Helpers/validators";

import axios from "axios";

const INITIAL_STATE = {
  email: "",
  isValid: false,
  loaded: false,
  submitDisabled: false,
  toggleAlert: false,
  severity: "warning",
  messageAlert: "",
  errors: null,
};

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.fb = props.props.firebase;
    this.validators = new Validators();
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSendResetPasswordLink(email) {
    let promise = new Promise((resolve, reject) => {
      axios
        .post(
          `${ROUTES.FUNCTION_BASE_URL}clients-client/sendForgotPassword`,
          JSON.stringify({ email: email }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true, submitDisabled: true });
    const { email } = this.state;

    if (email !== null) {
      this.onSendResetPasswordLink(email)
        .then(() => {
          this.setState({
            loading: false,
            submitDisabled: false,
          });
          window.location.href = ROUTES.FORGOT_PASSWORD_VERIFICATION;
        })
        .catch((error) => {
          this.setState({
            loading: false,
            submitDisabled: false,
            toggleAlert: true,
            severity: "error",
            messageAlert: error.message,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
        submitDisabled: false,
        toggleAlert: true,
        severity: "error",
        messageAlert: "Invalid Data.",
      });
      console.log("Invalid Data");
    }
  };

  onCopyPasteCut = (event) => {
    event.preventDefault();
  };

  render() {
    return (
      <ForgotPasswordWidget
        state={this.state}
        onChange={() => this.onChange.bind(this)}
        onSubmit={() => this.onSubmit.bind(this)}
        onCopyPasteCut={() => this.onCopyPasteCut.bind(this)}
        firebase={this.props.props.firebase}
      />
    );
  }
}

export default ForgotPasswordContainer;
