import React, { useContext, useEffect, useState } from "react";
import {
  makeStyles,
  useTheme,
  Container,
  CssBaseline,
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Hidden,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { compose } from "recompose";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import logo from "../../../contents/img/Rampver_Online_Logo-main.png";
import logo2 from "../../../contents/img/Rampver Online Logo Square.png";
import * as CONSTANTS from "./constants";
import AdditionalInformation from "./additionalInformation";
import PEPDeclaration from "./PEPDeclaration";
import * as ROUTES from "../../../constants/route";
import { AuthUserContext, withAuthorization } from "../../Session";
import { withFirebase } from "../../Firebase";
import Functions from "./functions";
import BasicKYCHandler from "../../GenericPages/basicKYCHandler";
import FATCA from "./FATCA";
import Summary from "./summary";
import { assignStatus } from "../../../services/metrics";


const drawerWidth = 350;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#256141",
    },
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#256141",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    fontFamily: "Gill Sans, Poppins",
    background: "white",
    height: "100%",
    width: "100%",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "100vh",
    overflow: "hidden",
    position: "relative",
  },

  navBar: {
    backgroundColor: "#fff",
  },
  brand: {
    maxWidth: "140px",
    margin: "10px auto 13px auto",
  },
  brand2: {
    maxWidth: "140px",
    height: "60px",
    margin: "5px 0 5px 0",
  },


}));

const LongKYCPage = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const smBreakpoint = useMediaQuery(theme.breakpoints.up("sm"));
  const context = useContext(AuthUserContext);

  const [additionalInfo, setAdditionalInfo] = useState(
    CONSTANTS.INITIAL_STATE.additionalInfo
  );
  const [uploadImg, setUploadImg] = useState(CONSTANTS.INITIAL_STATE.images);
  const [pepDeclaration, setPEPDeclaration] = useState(
    CONSTANTS.INITIAL_STATE.PEPDeclaration
  );
  const [fatca, setFATCA] = useState(
    CONSTANTS.INITIAL_STATE.FATCA
  );
  const [signature, setSignature] = useState(
    CONSTANTS.INITIAL_STATE.signature
  );
  const [modalAlert, setModalAlert] = useState(
    CONSTANTS.INITIAL_STATE.modalAlert
  );
  const [validation, setValidation] = useState({
    loading: false,
    openDrawer: false,
  });
  const [step, setStep] = useState({
    step: 1,
    fixStep: 1,
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const functions = new Functions();

  useEffect(() => {
     const userData = JSON.parse(window.localStorage.getItem("additionalInfo"));
     const bgcolor = "#fff";
     const bodyElt = document.querySelector("body");
     bodyElt.style.backgroundColor = bgcolor;
     setInitialLoad(true);

     const uid = context.uid;
     assignStatus({ uid, status: "LONG_KYC-1" });

     const localUserData = userData !== null && {
       primaryOccupation: true,
     };

     const usersRef = props.firebase.db.collection("users");
     const kycRef = usersRef.doc(uid).collection("KYC").doc("KYC");

     usersRef
       .doc(uid)
       .get()
       .then((snapshot) => {
         const data = snapshot.data();
         if (!data.hasLongKYC) {
           window.scrollTo(0, 0);
           setStep((prevState) => ({ ...prevState, step: -1 }));
         } else {
           buildData(userData !== null ? localUserData : data);
         }
       })
       .catch((error) => {
         console.log(error);
       });
     kycRef
       .get()
       .then((kycSnapshot) => {
         if (kycSnapshot) {
           const kycData = kycSnapshot.data();
           setAdditionalInfo((prevState) => ({
             ...prevState,
             isPrimaryId: kycData.ids.isPrimaryId,
             existingID: kycData.ids.idType === "None",
           }));
         } else {
           console.log("No data found kyc!");
         }
       })
       .catch((error) => {
         console.error(error);
       });
  }, []);

  useEffect(() => {
    if (
      additionalInfo.work?.primaryOccupation !== "" &&
      modalAlert.modalOpen === false
    ) {
      window.localStorage.setItem(
        "additionalInfo",
        JSON.stringify(additionalInfo)
      );
      window.localStorage.setItem(
        "PEPDeclaration",
        JSON.stringify(pepDeclaration)
      );
      window.localStorage.setItem("FATCA", JSON.stringify(fatca));
    }
  }, [additionalInfo, pepDeclaration,fatca]);

  const buildData = (datas) => {
    let additionalData;
    let pepDeclarationData;
    let fatcaData;
    if (datas.primaryOccupation) {
      const additionalInfo = JSON.parse(window.localStorage.getItem("additionalInfo"));
      const pepDeclaration = JSON.parse(
        window.localStorage.getItem("PEPDeclaration")
      );
      const fatca = JSON.parse(window.localStorage.getItem("FATCA"));

      additionalData = { ...additionalInfo };
      pepDeclarationData = {...pepDeclaration};
      fatcaData = { ...fatca };
    } else{
      additionalData={...additionalInfo}
      pepDeclarationData = { ...pepDeclaration };
      fatcaData = { ...fatca };
    }

    setValidation((prevState) => ({ ...prevState, openDrawer: false }));
    setAdditionalInfo(additionalData);
    setPEPDeclaration(pepDeclarationData);
    setFATCA(fatcaData);
    setInitialLoad(false);
  
  };

  const nextStep = () => {
    window.scrollTo(0, 0);

    setStep((prevState) => ({
      ...prevState,
      step: prevState.step + 1,
    }));
  };

  const fixedStep = (value) => {
    window.scrollTo(0, 0);

    setStep((prevState) => ({
      ...prevState,
      fixStep: prevState.fixStep === 4 ? 4 : value,
    }));
  };
  const prevStep = () => {
    window.scrollTo(0, 0);
    setStep((prevState) => ({
      ...prevState,
      step: prevState.step - 1,
    }));
  };

  const handleChangeAdditionalInfo = (value) => {
    setAdditionalInfo((prevState) => ({
      ...prevState,
      ids: {
        idType: value.selectedIDs,
        SSS: value.SSS,
        TIN: value.TIN,
      },
      businessAddress: {
        street: value.street,
        city: value.cityAddress,
        province: value.provinceAddress,
        zip: value.zip,
        country: value.countryAddress,
      },
      work: {
        primaryOccupation: value.primaryoccupation,
        nameOfEmployer: value.nameOfEmployer,
        businessNature: value.businessNature,
        annualIncomeAmount: value.annualIncomeAmount,
      },
    }));
  };

   const handleChangePEPDec = (value) => {
     if (value.governmentRelative === "0" && value.governmentWork === "0") {
             setPEPDeclaration({
               governmentWork: value.governmentWork,
               governmentRelative: value.governmentRelative,
               banks: "",
             });
       return setUploadImg((prevState) => ({
         ...prevState,
         idPEPDeclaration: null,
         idPEPDeclarationLabel: "",
         idPEPDeclarationPreview: null,
       }));
     } else {
       setPEPDeclaration({
         governmentWork: value.governmentWork,
         governmentRelative: value.governmentRelative,
         banks: value.banks,
       });
     }
   };
   const handleChangeFATCA = (value) => {
    if (value.usPerson === "0"){
      return setFATCA({
        usPerson: value.usPerson,
        usCitizen: "",
        usResident: "",
        usAlien: "",
        usPhone: "",
        usBorn: "",
        usAddressResidence: "",
        usAddressMailing: "",
        usAccount: "",
        usPOA: "",
        usCareOfAddress: "",
      });
    }
      setFATCA({
        usPerson: value.usPerson,
        usCitizen: value.usCitizen,
        usResident: value.usResident,
        usAlien: value.usAlien,
        usPhone: value.usPhone,
        usBorn: value.usBorn,
        usAddressResidence: value.usAddressResidence,
        usAddressMailing: value.usAddressMailing,
        usAccount: value.usAccount,
        usPOA: value.usPOA,
        usCareOfAddress: value.usCareOfAddress,
      });
     
   };
  
  const saveAndContinue = () => {
    setValidation((prevState) => ({ ...prevState, loading: true }));
    
     let requiredFields = [
       { field: additionalInfo.businessAddress.street, name: "Street" },
       { field: additionalInfo.businessAddress.city, name: "City" },
       { field: additionalInfo.businessAddress.province, name: "Province" },
       { field: additionalInfo.businessAddress.zip, name: "ZIP" },
       { field: additionalInfo.businessAddress.country, name: "Country" },
       {
         field: additionalInfo.work.primaryOccupation,
         name: "Primary Occupation",
       },
       { field: additionalInfo.work.nameOfEmployer, name: "Name of Employer" },
       {
         field: additionalInfo.work.businessNature,
         name: "Nature of Business",
       },
       { field: additionalInfo.work.annualIncomeAmount, name: "Annual Income" },
       { field: additionalInfo.ids.idType, name: "ID Type" },
     ];
      if (additionalInfo.existingID && additionalInfo.ids.idType ==="SSS") {
        requiredFields = requiredFields.concat([
          {
            field:
              additionalInfo.ids.SSS === undefined
                ? ""
                : additionalInfo.ids.SSS,
            name: "SSS",
          },
        
        ]);
      } 
      if (additionalInfo.existingID && additionalInfo.ids.idType === "TIN") {
        requiredFields = requiredFields.concat([
        
          {
            field:
              additionalInfo.ids.TIN === undefined
                ? ""
                : additionalInfo.ids.TIN,
            name: "TIN",
          },
        ]);
      } 
      if (fatca.usPerson === "1") {
        requiredFields = requiredFields.concat([
          {
            field: fatca.usPerson === undefined ? "" : fatca.usPerson,
            name: "US Person",
          },
          {
            field: fatca.usCitizen === undefined ? "" : fatca.usCitizen,
            name: "US Citizen",
          },
          {
            field: fatca.usResident === undefined ? "" : fatca.usResident,
            name: "US Resident",
          },
          {
            field: fatca.usAlien === undefined ? "" : fatca.usAlien,
            name: "US Resident Alien",
          },
          {
            field: fatca.usPhone === undefined ? "" : fatca.usPhone,
            name: "US Telephone Number",
          },
          {
            field: fatca.usBorn === undefined ? "" : fatca.usBorn,
            name: "Born in US",
          },
          {
            field:
              fatca.usAddressResidence === undefined
                ? ""
                : fatca.usAddressResidence,
            name: "US Residence Address",
          },
          {
            field:
              fatca.usAddressMailing === undefined
                ? ""
                : fatca.usAddressMailing,
            name: "US Mailing Address",
          },
          {
            field: fatca.usAccount === undefined ? "" : fatca.usAccount,
            name: "US Account",
          },
          {
            field: fatca.usPOA === undefined ? "" : fatca.usPOA,
            name: "US POA",
          },
          {
            field:
              fatca.usCareOfAddress === undefined ? "" : fatca.usCareOfAddress,
            name: "US Care Of Address",
          },
        ]);
      } 

      if (
        (pepDeclaration.governmentWork === "1" ||
        pepDeclaration.governmentRelative === "1")
      ) {
        requiredFields = requiredFields.concat([
          { field: pepDeclaration.governmentWork, name: "Government Work" },
          {
            field: pepDeclaration.governmentRelative,
            name: "Government Relative",
          },
          { field: pepDeclaration.banks, name: "Banks" },
        ]);
      } 

      for (const { field, name } of requiredFields) {
        if (field === "" || field === null) {
          setValidation((prevState) => ({
            ...prevState,
            loading: false,
          }));
          setModalAlert((prevState) => ({
            ...prevState,
            modalOpen: true,
            modalTitle: `The ${name} field is empty. Please complete the form!`,
            modalSuccess: false,
          }));
          return false;
        } 
      }
   
    if (
      (pepDeclaration.governmentWork === "1" ||
        pepDeclaration.governmentRelative === "1") &&
      uploadImg.idPEPDeclaration === null
    ) {
      setValidation((prevState) => ({
        ...prevState,
        loading: false,
      }));
      setModalAlert((prevState) => ({
        ...prevState,
        modalOpen: true,
        modalTitle: "Please upload your PEP Declaration.",
        modalSuccess: false,
      }));
      return false;
    }
     if (
       (additionalInfo.isPrimaryId === undefined ||
       additionalInfo.isPrimaryId === false) &&
       (  uploadImg.idPhotoFront === null ||
         uploadImg.idPhotoBack === null)
     ) {
       setValidation((prevState) => ({
         ...prevState,
         loading: false,
       }));
       setModalAlert((prevState) => ({
         ...prevState,
         modalOpen: true,
         modalTitle: "Please upload your ID.",
         modalSuccess: false,
       }));
     }
     else{
       functions
         .doAddLongKYC(
           context.uid,
           uploadImg,
           additionalInfo,
           pepDeclaration,
           signature,
           fatca
         )
         .then((res) => {
           if (!res.data.ok) throw new Error(res.data.message);
           setValidation((prevState) => ({
             ...prevState,
             loading: false,
           }));
           return setModalAlert((prevState) => ({
             ...prevState,
             modalOpen: true,
             modalTitle: "Your Client Application was successfully submitted!",
             modalContent:
               "We will process your application within 2-3 business days. Once approved, you can already start investing!",
             modalSuccess: true,
           }));
         })
         .catch((err) => {
           let errMsg = err.message;
           console.log(errMsg);
           try {
             let errObj = JSON.parse(err.message);
             
             errMsg = `Your uploaded file for your ${errObj.fieldname}, ${errObj.filename}, is not an image or a .pdf document. Please reupload a compatible file!`;
           } catch {
             if (errMsg === "Network Error")
               errMsg =
                 "Failed to submit your Client Application due to a network error. Please check your internet connection and try again.";
           }
           setValidation((prevState) => ({
             ...prevState,
             loading: false,
           }));
           setModalAlert((prevState) => ({
             ...prevState,
             modalOpen: true,
             modalTitle: "Uh oh, an error has occurred!",
             modalContent: errMsg,
             modalSuccess: false,
           }));
         });
 

     }
    
  };


  const handleDropzone = (target) => async (files) => {
    setUploadImg((prevState) => ({
      ...prevState,
      [target]: files[0].file,
      [`${target}Label`]: files[0].file.name,
      [`${target}Preview`]: files[0],
    }));
  };
  const handleDropzoneDelete = (target) => async () => {
    setUploadImg((prevState) => ({
      ...prevState,
      [target]: null,
      [`${target}Label`]: "",
      [`${target}Preview`]: null,
    }));
  };

  const handleDrawerOpen = () => {
    setValidation((prevState) => ({
      ...prevState,
      openDrawer: !prevState.openDrawer,
    }));
  };


  const handleModalClose = () => {
    setModalAlert((prevState) => ({ ...prevState, modalOpen: false }));
    if (modalAlert.modalSuccess) {
      props.history.push(ROUTES.DASHBOARD);
    }
  };

   const handleSignature = (url) => {
     setSignature({signatureUrl:url})
    };

   const handleEraseCurrentSig = () => {
    setSignature({ signatureUrl: null });
    };

  const renderSwitch = (step) => {
    switch (step) {
      case 1:
        return (
          <AdditionalInformation
            additionalInfo={additionalInfo}
            uploadImg={uploadImg}
            editKYC={false}
            handleDropzone={handleDropzone}
            handleDropzoneDelete={handleDropzoneDelete}
            nextStep={nextStep}
            fixedStep={fixedStep}
            smBreakpoint={smBreakpoint}
            handleChangeData={handleChangeAdditionalInfo}
            initialLoad={initialLoad}
          />
        );
      case 2:
        return (
          <PEPDeclaration
            PEPData={pepDeclaration}
            uploadImg={uploadImg}
            prevStep={prevStep}
            editKYC={false}
            handleDropzone={handleDropzone}
            handleDropzoneDelete={handleDropzoneDelete}
            nextStep={nextStep}
            fixedStep={fixedStep}
            handleChangePEPDec={handleChangePEPDec}
            initialLoad={initialLoad}
          />
        );
      case 3:
        return (
          <FATCA
            FATCAData={fatca}
            prevStep={prevStep}
            editKYC={false}
            nextStep={nextStep}
            fixedStep={fixedStep}
            handleChangeFATCA={handleChangeFATCA}
            initialLoad={initialLoad}
          />
        );
      case 4:
        return (
          <Summary
            modalAlert={modalAlert}
            loading={validation}
            additionalInfo={additionalInfo}
            PEPData={pepDeclaration}
            FATCAData={fatca}
            signature={signature}
            prevStep={prevStep}
            handleSignature={handleSignature}
            handleEraseCurrentSig={handleEraseCurrentSig}
            saveAndContinue={saveAndContinue}
            handleModalClose={handleModalClose}
            isUpdateKYC={true}
          />
        );

      default:
        return <BasicKYCHandler history={props.history} />;
    }
  };

  const drawer = (
    <>
      <div className={classes.drawerHeader}>
        <img className={classes.brand} src={logo2} alt="rampver-logo"></img>

        {!validation.openDrawer ? (
          ""
        ) : (
          <IconButton onClick={() => handleDrawerOpen()}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon style={{ color: "white" }} />
            ) : (
              <ChevronRightIcon style={{ color: "white" }} />
            )}
          </IconButton>
        )}
      </div>
      <div className="drawer-container">
        <label className="drawer-title" style={{ color: "white" }}>
          Additional Application
        </label>
        <p className="drawer-subtitle" style={{ color: "white" }}>
          Please make sure all your personal information are correct and
          consistent with your government issued ID&apos;s.
        </p>
        <div className="drawer-item-list">
          <List>
            {[
              "Additional Information",
              "PEP Declaration",
              "FATCA",
              "Summary",
            ].map((item, index) => {
              const stepNavigation = index + 1;
              return (
                <ListItem
                  onClick={() =>
                    step.fixStep >= index + 1 &&
                    setStep((prevState) => ({
                      ...prevState,
                      step: stepNavigation,
                    }))
                  }
                  style={{
                    cursor: step.fixStep >= index + 1 ? "pointer" : "default",
                  }}
                  key={index}
                >
                  <ListItemIcon style={{ marginRight: "0" }}>
                    {step.fixStep > index + 1 ? (
                      <CheckCircleOutlineIcon color="primary" />
                    ) : (
                      <ChevronRightIcon style={{ color: "white" }} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    className={clsx(
                      "list-item-default",
                      step.fixStep > index + 1 ? "process-completed" : "",
                      step.step === index + 1 ? "process-current" : ""
                    )}
                  >
                    <span
                      className={`${
                        step.fixStep >= index + 1 && "sidebar-text"
                      }`}
                    >
                      {" "}
                      {item}{" "}
                    </span>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </div>
      </div>
    </>
  );

  return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, classes.navBar)}
        >
          <Toolbar>
            <IconButton
              style={{ color: "#256141" }}
              aria-label="open drawer"
              onClick={() => handleDrawerOpen()}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
            <img className={classes.brand2} src={logo} alt="rampver-logo"></img>
          </Toolbar>
        </AppBar>

        <Hidden smDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="permanent"
            anchor="left"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={validation.openDrawer}
            onClose={() => handleDrawerOpen()}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <div className={classes.mainContent}>
          <main className={clsx(classes.content)}>
            <div className={classes.drawerHeader} />
            <Container component="main">{renderSwitch(step.step)}</Container>
          </main>
        </div>
      </div>
  );
};

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(LongKYCPage);
